// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Uo01jOdIJ26UQfbbfN6Y{
  display: grid;
  grid-template-columns: [left] 1fr [body] 4fr [right] 1fr;
}
.LLFoDi7NXRYdHL8rq1gD{
  grid-column-start: body;
  margin-bottom: 75px;
}
.eZaTG85Iyk_BBgDlAPMq{
  font-size:18px;
  margin-right:75px;
}
.DmUeugIo_4sWxkSJkQYW{
  text-transform:uppercase;
}
.rF9M59bgKrNUCOY6LukL{
  font-weight:bold;
  color: grey;
}
.YBocUR1x78JVve_86mYJ{
  margin-top: 60px;
  margin-bottom: 60px;
}
.CLyk659FyB2vwTwwLETd{
  cursor: pointer;
}
.RC1TwLZRdDvqo31rZkOo{
  box-shadow: none;
  background-color: aliceblue;
  border-radius: 30px;
}
.RC1TwLZRdDvqo31rZkOo:hover{
  background-color:#d7ecff;
}
.wnPWsgMSHoTJPVG18qb8{
  grid-column-start: body;
}
.ZETYBtdzWSxLRDsfsiYC{
  color: #DC493A;

  &.MuiTab-root {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight:600;
    color: #DC493A;
  }
  &.MuiTabs-indicator{
    color: #DC493A;
    background-color: #DC493A;
  }
  &.MuiTab-root.Mui-selected {
    color: #DC493A;
  }
  &.MuiTab-root.Mui-focusVisible {
    color: #DC493A;
  }
}
.pHOcW9UJmRFojmovtKYH{
  background-color: #DC493A;
  color: #DC493A;
}
.eZaTG85Iyk_BBgDlAPMq a{
  font-weight:500;
  text-decoration: underline;
  text-decoration-style:dotted;
  color: #DC493A;
  font-size:18px;
}`, "",{"version":3,"sources":["webpack://./client/components/UserProfile/UserProfile.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wDAAwD;AAC1D;AACA;EACE,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,cAAc;;EAEd;IACE,wCAAwC;IACxC,eAAe;IACf,cAAc;EAChB;EACA;IACE,cAAc;IACd,yBAAyB;EAC3B;EACA;IACE,cAAc;EAChB;EACA;IACE,cAAc;EAChB;AACF;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,eAAe;EACf,0BAA0B;EAC1B,4BAA4B;EAC5B,cAAc;EACd,cAAc;AAChB","sourcesContent":[".container{\n  display: grid;\n  grid-template-columns: [left] 1fr [body] 4fr [right] 1fr;\n}\n.body{\n  grid-column-start: body;\n  margin-bottom: 75px;\n}\n.bodyText{\n  font-size:18px;\n  margin-right:75px;\n}\n.cardName{\n  text-transform:uppercase;\n}\n.cardSubhead{\n  font-weight:bold;\n  color: grey;\n}\n.header{\n  margin-top: 60px;\n  margin-bottom: 60px;\n}\n.gridItemContainer{\n  cursor: pointer;\n}\n.gridItem{\n  box-shadow: none;\n  background-color: aliceblue;\n  border-radius: 30px;\n}\n.gridItem:hover{\n  background-color:#d7ecff;\n}\n.tabBox{\n  grid-column-start: body;\n}\n.tabLabel{\n  color: #DC493A;\n\n  &:global(.MuiTab-root) {\n    font-family: 'IBM Plex Sans', sans-serif;\n    font-weight:600;\n    color: #DC493A;\n  }\n  &:global(.MuiTabs-indicator){\n    color: #DC493A;\n    background-color: #DC493A;\n  }\n  &:global(.MuiTab-root.Mui-selected) {\n    color: #DC493A;\n  }\n  &:global(.MuiTab-root.Mui-focusVisible) {\n    color: #DC493A;\n  }\n}\n.tabIndicator{\n  background-color: #DC493A;\n  color: #DC493A;\n}\n.bodyText a{\n  font-weight:500;\n  text-decoration: underline;\n  text-decoration-style:dotted;\n  color: #DC493A;\n  font-size:18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Uo01jOdIJ26UQfbbfN6Y`,
	"body": `LLFoDi7NXRYdHL8rq1gD`,
	"bodyText": `eZaTG85Iyk_BBgDlAPMq`,
	"cardName": `DmUeugIo_4sWxkSJkQYW`,
	"cardSubhead": `rF9M59bgKrNUCOY6LukL`,
	"header": `YBocUR1x78JVve_86mYJ`,
	"gridItemContainer": `CLyk659FyB2vwTwwLETd`,
	"gridItem": `RC1TwLZRdDvqo31rZkOo`,
	"tabBox": `wnPWsgMSHoTJPVG18qb8`,
	"tabLabel": `ZETYBtdzWSxLRDsfsiYC`,
	"tabIndicator": `pHOcW9UJmRFojmovtKYH`
};
export default ___CSS_LOADER_EXPORT___;
