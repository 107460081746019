// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.siC9BzXoQuZZNS4whIz5{
  display: grid;
  grid-template-columns: [left] 1fr [body] 1fr [right] 1fr;
}
.QLdnki9NGNTX_MQWHolz{
  grid-column-start: body;
  justify-self:center;

  font-size: 14px;
  background: #e7faed;
  border: solid #4caf50;
  padding: 20px;
  border-radius: 30px;
  margin-top: 20px;
}
.QLdnki9NGNTX_MQWHolz li {
  font-style: italic;
}
.EUXSBxyFpF2Gpf7vGX1p{
  grid-column-start: body;
  margin-top: 20px;

  background-color:aliceblue;
  border-radius:30px;
}
.d8QAHPFo8AR0hOjC6DY4{
  display:flex;
  flex-direction:column;
  align-items:center;
}
.X78WDw9IMxXp32SpuPJL{
  margin:30px;
  margin-bottom:60px;
}
.ZUehuuoOFv8KvkJblVJg{
  margin-top: 60px;
  margin-bottom:60px;
  grid-column-start: body;
  text-align: center;
  text-transform:uppercase;
}
.GKHB1rRt9RrMGT5FsvUU{
  width:100%;
  background-color: white; 
}
.Cn9NSZESYvz0BFrMjNO2{
  font-size:13px;
}
.dXhHrUcteEJpbjBfFjbD{
  grid-column-start: body;
  justify-self:center;

  font-size: 14px;
  background: #fcf0f0;
  border: solid #ef5350;
  padding: 20px;
  border-radius: 30px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./client/components/AuthForm/AuthForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wDAAwD;AAC1D;AACA;EACE,uBAAuB;EACvB,mBAAmB;;EAEnB,eAAe;EACf,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,gBAAgB;;EAEhB,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,UAAU;EACV,uBAAuB;AACzB;AACA;EACE,cAAc;AAChB;AACA;EACE,uBAAuB;EACvB,mBAAmB;;EAEnB,eAAe;EACf,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".container{\n  display: grid;\n  grid-template-columns: [left] 1fr [body] 1fr [right] 1fr;\n}\n.loginMessage{\n  grid-column-start: body;\n  justify-self:center;\n\n  font-size: 14px;\n  background: #e7faed;\n  border: solid #4caf50;\n  padding: 20px;\n  border-radius: 30px;\n  margin-top: 20px;\n}\n.loginMessage li {\n  font-style: italic;\n}\n.bodyContainer{\n  grid-column-start: body;\n  margin-top: 20px;\n\n  background-color:aliceblue;\n  border-radius:30px;\n}\n.body{\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n.buttonContainer{\n  margin:30px;\n  margin-bottom:60px;\n}\n.header{\n  margin-top: 60px;\n  margin-bottom:60px;\n  grid-column-start: body;\n  text-align: center;\n  text-transform:uppercase;\n}\n.textFieldBox{\n  width:100%;\n  background-color: white; \n}\n.textFieldInput{\n  font-size:13px;\n}\n.errorMessage{\n  grid-column-start: body;\n  justify-self:center;\n\n  font-size: 14px;\n  background: #fcf0f0;\n  border: solid #ef5350;\n  padding: 20px;\n  border-radius: 30px;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `siC9BzXoQuZZNS4whIz5`,
	"loginMessage": `QLdnki9NGNTX_MQWHolz`,
	"bodyContainer": `EUXSBxyFpF2Gpf7vGX1p`,
	"body": `d8QAHPFo8AR0hOjC6DY4`,
	"buttonContainer": `X78WDw9IMxXp32SpuPJL`,
	"header": `ZUehuuoOFv8KvkJblVJg`,
	"textFieldBox": `GKHB1rRt9RrMGT5FsvUU`,
	"textFieldInput": `Cn9NSZESYvz0BFrMjNO2`,
	"errorMessage": `dXhHrUcteEJpbjBfFjbD`
};
export default ___CSS_LOADER_EXPORT___;
