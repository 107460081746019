// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iKdmZhVKfMlrjQrqUKdK{
  margin-top:80px;
}`, "",{"version":3,"sources":["webpack://./client/Routes.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".pageContent{\n  margin-top:80px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": `iKdmZhVKfMlrjQrqUKdK`
};
export default ___CSS_LOADER_EXPORT___;
