// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o6wK8_Z53vQeGBFZWOqN{
  min-height:30vh;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:70px;
  margin-left:20px;
  margin-right:20px;
  border-top: 1px solid grey;
}
.sAfBa_Pc1CnLMC7QukFA{
  justify-content: center;
  align-items: center;
  display:flex;
  flex-direction:row;
}
.hw5oQtgBXtdQzueBlHQQ{
  justify-content: center;
  align-items: center;
}
.r1Go8MbCIkQc_SDMKhkc{
  height:100px;
}
.PDP6NwTSKTw21ntsSn5S{
  font-weight:700;
  color:gray
}
.vLCFkqDH2RwGQWfWryai{
  display:flex;
  flex-direction: column;
  margin-left:30px;
}
.vLCFkqDH2RwGQWfWryai a {
  color: black;
  font-size: 14px;
  display: inline-flex;

  &:hover{
    color: #DC493A;
    font-weight:600;
  }

  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}

.vLCFkqDH2RwGQWfWryai a::before {
  content: attr(title);
  content: attr(title) / "";
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./client/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;AAC5B;AACA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;EACf;AACF;AACA;EACE,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,eAAe;EACf,oBAAoB;;EAEpB;IACE,cAAc;IACd,eAAe;EACjB;;EAEA,sBAAsB;EACtB,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,SAAS;EACT,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":[".container{\n  min-height:30vh;\n  display:flex;\n  justify-content: center;\n  align-items: center;\n  margin-top:70px;\n  margin-left:20px;\n  margin-right:20px;\n  border-top: 1px solid grey;\n}\n.content{\n  justify-content: center;\n  align-items: center;\n  display:flex;\n  flex-direction:row;\n}\n.logoContainer{\n  justify-content: center;\n  align-items: center;\n}\n.logo{\n  height:100px;\n}\n.aboutHeader{\n  font-weight:700;\n  color:gray\n}\n.links{\n  display:flex;\n  flex-direction: column;\n  margin-left:30px;\n}\n.links a {\n  color: black;\n  font-size: 14px;\n  display: inline-flex;\n\n  &:hover{\n    color: #DC493A;\n    font-weight:600;\n  }\n\n  flex-direction: column;\n  justify-content: space-between;\n  text-decoration: none;\n}\n\n.links a::before {\n  content: attr(title);\n  content: attr(title) / \"\";\n  height: 0;\n  visibility: hidden;\n  overflow: hidden;\n  user-select: none;\n  pointer-events: none;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `o6wK8_Z53vQeGBFZWOqN`,
	"content": `sAfBa_Pc1CnLMC7QukFA`,
	"logoContainer": `hw5oQtgBXtdQzueBlHQQ`,
	"logo": `r1Go8MbCIkQc_SDMKhkc`,
	"aboutHeader": `PDP6NwTSKTw21ntsSn5S`,
	"links": `vLCFkqDH2RwGQWfWryai`
};
export default ___CSS_LOADER_EXPORT___;
